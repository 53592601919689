module.exports = {
  siteTitle: 'Hello! I\'m Eveline!',
  siteDescription: `My Homepage`,
  authorName: 'Eveline Rehfeldt',
  twitterUsername: '_franciscodf',
  githubUsername: 'santosfrancisco',
  authorAvatar: '/images/eveline.JPG',
  authorDescription: `Underwriter for Special Risks. I speak 5 languages fluently, have a LL.M in \"Insurance Law\" and live with my family in Münster.
    I love to negotiate contracts that are good for every party.`,
  skills: [
    {
      name: 'International Maritime Law',
      level: 70
    },
    {
      name: 'Insurance Law',
      level: 60
    },
    {
      name: 'Excel',
      level: 60
    },
    {
      name: 'German | English | Ukrainian | Russian',
      level: 100
    },
    {
      name: 'French | Spanish',
      level: 70
    },
    /* more skills here */
  ],
  jobs: [
    /* more jobs here */
    {
      company: "Hansekuranz Kontor",
      begin: {
        month: 'aug',
        year: '2016'
      },
      duration: null,
      occupation: "Underwriter",
      description: "Kurze Beschreibung deiner Tätigkeit"
  
    },  {
      company: "University of Münster",
      begin: {
        month: 'apr',
        year: '2018'
      },
      duration: '2 yr',
      occupation: "LL.M Versicherungsrecht",
      description: "Ich studierte berufsbegleitend und habe ... blalblabal."
  
    }, {
      company: "Coole Firma auf Zypern",
      begin: {
        month: 'dec',
        year: '2016'
      },
      duration: '1 yr e 5 mos',
      occupation: "Trade assistant",
      description: "Hier muss dein Lebenslauf eingebaut werden."
    }, {
      company: "Anapro",
      begin: {
        month: 'set',
        year: '2012'
      },
      duration: '4 yrs e 3 mos',
      occupation: "Support Technician",
      description: "Responsible for the implementation and parameterization of the system, training and customer support. Acting also in person in real estate launches guaranteeing the success and good use of the tool."
  
    },
  ],
  social: {
    linkedin: "https://de.linkedin.com/in/eveline-rehfeldt-7125aab",
    email: "e.rehfeldt@internetschach.de"
  },
  siteUrl: 'https://santosfrancisco.github.io/gatsbystarter-cv',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/muenster.jpg',
  googleAnalyticsId: 'UA-000000000-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  fontColor: "#000000cc",
  enableDarkmode: false, // If true, enables dark mode switch
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portifolio',
      url: '/portifolio',
    }
  ]
}